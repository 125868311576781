import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import { useModal } from "../../../features/insightTemplates/components/ViewFlowDetailContext";
const handleStyle = { opacity: 0 };

export default memo(({ data }) => {
  const { openViewFlowDetailModal, setTabDetails } = useModal();

  const onClick = () => {
    setTabDetails(data?.nodeDetails?.tabs || []);
    openViewFlowDetailModal();
  };
  return (
    <div className="w-64 z-10">
      <Handle
        id="top"
        style={handleStyle}
        position={Position.Top}
        type="source"
      />
      <Handle
        id="right"
        style={handleStyle}
        position={Position.Right}
        type="source"
      />
      <Handle
        id="bottom"
        style={handleStyle}
        position={Position.Bottom}
        type="source"
      />
      <Handle
        id="left"
        style={handleStyle}
        position={Position.Left}
        type="source"
      />
      <div className="relative">
        <div
          style={{ boxShadow: " 0px 8px 24px 0px rgba(105, 112, 149, 0.10)" }}
          className="flex flex-col items-center justify-start font-inter-400 font-normal text-xxs leading-4 text-brand-ai-200 rounded-xl w-full"
        >
          <div className="bg-brand-ai-700 font-inter-500 font-medium w-full p-4 flex items-center justify-center rounded-t-xl">
            {data.table}
          </div>
          <div className="py-2">
            {data?.items.map((item, index) => (
              <div
                key={index}
                className={index < data?.items.length - 1 && "border-b mb-2"}
              >
                <div className="px-4">
                  <p className="capitalize">{item?.action}:</p>
                  <ul className="w-fit pl-2.5">
                    {item?.values?.map((data) => {
                      return <li>{data}</li>
                    })}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
        {data.hasNodeDetails && (
          <div
            className="absolute -bottom-50 right-0 font-inter-500 font-medium text-xxs leading-4 text-brand-ai-100"
            onClick={onClick}
          >
            {data?.nodeDetails?.text ? data?.nodeDetails?.text : "View Details"}
          </div>
        )}
      </div>
    </div>
  );
});
