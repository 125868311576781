import React from "react";
import { ReactFlowProvider } from "reactflow";
import { ReactFlowComponent } from "./components/ReactFlowComponent";

// as we are accessing the internal React Flow state in our component, we need to wrap it with the ReactFlowProvider
const ReactFlowWrapper = ({ initialNodes, initialEdges, direction, className, style, height, width, fit }) => {
  return (
    <ReactFlowProvider>
      <ReactFlowComponent
        initialEdges={initialEdges}
        initialNodes={initialNodes}
        direction={direction}
        className={className}
        style={style}
        height={height}
        width={width}
        fit={fit}
      />
    </ReactFlowProvider>
  );
};

export default ReactFlowWrapper;
