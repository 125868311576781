import { Menu } from "@headlessui/react";
import clsx from "clsx";

export const DropdownItem = ({
  children,
  onClick = () => {},
  className,
  ...props
}) => {
  return (
    <Menu.Item onClick={(event) => onClick(event)} {...props}>
      {({ active }) => (
        <span
          className={clsx(
            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
            "block px-4 py-2 text-sm",
            "cursor-pointer",
            "select-none",
            className
          )}
        >
          {children}
        </span>
      )}
    </Menu.Item>
  );
};
