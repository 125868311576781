import { initReactQueryAuth } from "./react-query-auth";

import { Spinner } from "@/components/Elements";
import {
  loginWithEmailAndPassword,
  getUser,
  registerWithEmailAndPassword,
} from "@/features/auth";
import storage from "@/utils/storage";
import { updateUser } from "@/features/auth/api/updateUser";

async function handleUserResponse(data) {
  const { token, auth } = data;
  storage.setToken(token);
  return auth;
}

async function loadUser() {
  if (storage.getToken()) {
    return true;
    // const data = await getUser();
    // return data;
  }
  return null;
}

async function loginFn(data) {
  try {
    const response = await loginWithEmailAndPassword(data);
    const user = await handleUserResponse(response);
    return user;
  } catch (error) {
  }
}

async function registerFn(data) {
  const response = await registerWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  console.log("first")
  storage.clearToken();
  // window.location.assign(window.location.origin);
}

async function updateUserFn({ userId, data }) {
  await updateUser(userId, data);
  return data;
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  updateUserFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="xl" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
