import { Tab as HeadlessTab } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

export const Tab = ({ id, children, locked,className }) => {
  return (
    <HeadlessTab.Panel
      key={id}
      className={twMerge("outline-none py-3",className)}
    >
      {children}
    </HeadlessTab.Panel>
  );
};