import { twMerge } from "tailwind-merge";
import * as React from "react";

export const FieldWrapper = (props) => {
  const { label, className, error, children, wrapperClassName } = props;
  return (
    <div className="w-full">
      <label
        className={twMerge("block text-sm font-medium text-gray-700", className)}
      >
        {label}
        <div className={`w-full ${wrapperClassName}`}>{children}</div>
      </label>
      {error?.message && (
        <div
          role="alert"
          aria-label={error.message}
          className="text-sm font-normal text-red-500"
        >
          {error.message}
        </div>
      )}
    </div>
  );
};
