import { Tab as HeadlessTab } from "@headlessui/react";
import { Lock } from "phosphor-react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

const tabSizes = {
  md: "text-base min-w-16 py-2",
  sm: "text-xs min-w-14 py-1",
};

export const Tabs = ({
  children,
  selectedIndex = 0,
  size = "md",
  tabNavigatorGroupClassName,
  tabNavigatorButtonClassName,
  onTabChange,
  selectedClassName,
}) => {
  // Filter out any null or undefined children
  const validChildren = children.filter((child) => child);

  const handleTabChange = (index) => {
    if (onTabChange) {
      onTabChange(validChildren[index].props.title); // Pass the title of the selected tab
    }
  };
  return (
    <div className="w-full rounded-md">
      <HeadlessTab.Group
        defaultIndex={selectedIndex}
        onChange={handleTabChange}
      >
        <HeadlessTab.List
          className={twMerge(
            "inline-flex rounded-md bg-brand-gray-300",
            tabNavigatorGroupClassName
          )}
        >
          {validChildren.map((tab) => (
            <HeadlessTab
              key={tab.props.id}
              className={({ selected }) =>
                twMerge(
                  "flex items-center leading-5 text-t-highlight rounded-md w-max",
                  "focus:outline-none focus:ring-2",
                  "px-5  font-inter-400 font-normal min-w-16",
                  selected &&
                    `border-b-2 font-inter-600 font-semibold ${selectedClassName}`,
                  tabSizes[size],
                  tabNavigatorButtonClassName
                )
              }
              disabled={tab.props.disabled}
            >
              {tab.props.title}
              {tab.props.disabled && <Lock className="ml-2" />}
            </HeadlessTab>
          ))}
        </HeadlessTab.List>
        <HeadlessTab.Panels className="mt-2">
          {validChildren.map((tab) => (
            <HeadlessTab.Panel key={tab.props.id}>
              {tab.props.children}
            </HeadlessTab.Panel>
          ))}
        </HeadlessTab.Panels>
      </HeadlessTab.Group>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(["sm", "md"]),
  selectedIndex: PropTypes.number,
};
