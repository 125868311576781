import { useEffect, useState } from "react";
import { TableWithPagination } from "@/components/Elements/TableWithPagination";
import { ArrowLeft } from "phosphor-react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrowNight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { twMerge } from "tailwind-merge";
import ReactFlowWrapper from "@/components/FlowComponent/ReactFlowChart";
import { useModal } from "./ViewFlowDetailContext";
import { Button } from "@/components/Elements";
import { useNewInsightContext } from "./NewInsightContext";


const CodeTab = ({ tabData }) => {
  return (
    <>
      <div
        style={{ maxHeight: "80vh" }}
        className="flex flex-col gap-6 mx-6 mb-6 overflow-y-scroll"
      >
        <div className="flex flex-col gap-3">
          <h2 className="font-inter-600 font-semibold text-base text-brand-ai-200 leading-6">
            Code
          </h2>
          <SyntaxHighlighter
            language="javascript"
            style={tomorrowNight}
            showLineNumbers
            wrapLongLines={true}
            className="rounded-xl px-5 text-xs"
            key={`${tabData.code}-javascript`}
          >
            {tabData?.code}
          </SyntaxHighlighter>
        </div>
        <div className="flex flex-col gap-3">
          <h3 className="font-inter-600 font-semibold text-base text-brand-ai-200 leading-6">
            Code Explanation
          </h3>
          {tabData.code_description &&
            tabData.code_description.length > 0 &&
            tabData.code_description.map((data, index) => (
              <>
                {data?.header && (
                  <p className="font-inter-600 font-semibold text-brand-ai-gray-100 text-sm">
                    {data.header}
                  </p>
                )}
                <div
                  className="text-sm font-inter-400 font-normal leading-5 tracking-wide"
                  dangerouslySetInnerHTML={{ __html: data.text }}
                ></div>
              </>
            ))}
        </div>
      </div>
    </>
  );
};

const SQLTab = ({ tabData, goBack, showBack = true }) => {
  return (
    <>
      {/* <div
                className="px-8 overflow-y-scroll scrollbar-hide"
                style={{ maxHeight: "80vh" }}
              >
                Flow chart
              </div>
              <div className="flex justify-end mr-6 mb-6 p-2">
                <Button
                  onClick={handleViewSqlButtonClick}
                  className="bg-brand-ai-100 rounded-lg text-xs leading-5 font-inter-400 font-normal px-2 py-1"
                >
                  View SQL
                </Button>
              </div> */}
      {showBack && <div
        className="mt-4 mx-6 mb-6 gap-1 inline-flex items-center rounded cursor-pointer"
        onClick={() => goBack()}
      >
        <ArrowLeft />
        Back
      </div>}
      <div
        style={{ maxHeight: "80vh" }}
        className="flex flex-col gap-6 mx-6 mb-6 overflow-y-auto no-scrollbar"
      >
        <div className="flex flex-col gap-3">
          <h2 className="font-inter-600 font-semibold text-base text-brand-ai-200 leading-6">
            SQL Query
          </h2>
          <SyntaxHighlighter
            style={tomorrowNight}
            // showLineNumbers
            className="rounded-xl px-5 text-xs"
            PreTag={"div"}
            wrapLongLines={true}
            children={tabData?.sql}
            key={`${tabData.sql}-sql`}
            language="sql"
          ></SyntaxHighlighter>
        </div>
        <div className="flex flex-col gap-3">
          <h3 className="font-inter-600 font-semibold text-base text-brand-ai-200 leading-6">
            SQL Explanation
          </h3>
          {tabData.sql_description &&
            tabData.sql_description.length > 0 &&
            tabData.sql_description.map((data, index) => (
              <>
                {data?.header && (
                  <p className="font-inter-600 font-semibold text-brand-ai-gray-100 text-sm">
                    {data.header}
                  </p>
                )}
                <div
                  className="text-sm font-inter-400 font-normal leading-5 tracking-wide"
                  dangerouslySetInnerHTML={{ __html: data.text }}
                ></div>
              </>
            ))}
        </div>
      </div>
    </>
  );
};

const TableTab = ({
  tabData,
  className,
  itemsPerPage = 10,
  tablePageClassName,
  tableClassName,
}) => {
  const [itemsPerPageHandle, setItemsPerPageHandle] = useState(itemsPerPage);

  const setTableRow = (value) => {
    setItemsPerPageHandle(value);
  }

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      // let newItemsPerPage = 10;
      if (windowHeight > 700) {
        setTableRow(15);
      } else if (windowHeight > 500) {
        setTableRow(10);
      }

      // setItemsPerPage(newItemsPerPage);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className={twMerge(`px-8 py-4 h-full overflow-auto`, className)}>
        <TableWithPagination
          key={itemsPerPageHandle}
          items={tabData.values}
          itemsPerPage={itemsPerPageHandle}
          displayShowButton={false}
          columns={tabData.headers}
          headerClassName="bg-brand-ai-100"
          columnClass="text-white text-xs p-2 font-inter-500 font-medium"
          rowClassName="font-inter-400 font-normal text-xs border px-1 py-2"
          isDataSource={false}
          className={tableClassName ? tableClassName : "pt-2"}
          tablePageClassName={tablePageClassName}
        />
      </div>
    </>
  );
};

const ListTab = ({ tabData, isModal = false }) => {
  const { size, openThoughtProcess } = useNewInsightContext();
  return (
    <>
      <div
        style={{ maxHeight: `${isModal ? "80vh" : "auto"}` }}
        className={`p-8 overflow-y-scroll scrollbar-hide ${!isModal ? "h-full" : "h-auto"}`}
      >
        {tabData?.html_values ? (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: tabData?.html_values }}
            ></div>
          </>
        ) : (
          <ul className="text-sm font-inter-400 font-normal leading-5 tracking-wide">
            {tabData?.values?.map((e, index) => (
              <li key={index}>{e}</li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

const ERDiagramTab = ({ tabData }) => {
  const { setData, setStep } = useModal();

  const handleViewSqlButtonClick = () => {
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <div className="relative">
      <ReactFlowWrapper
        key={tabData?.er_diagram?.nodes?.length}
        initialNodes={tabData?.er_diagram?.nodes}
        initialEdges={tabData?.er_diagram?.edges}
        direction="LR"
        className={"h-96"}
        height={450}
        fit={true}
      />
      <div className="absolute left-6 bottom-3">
        <Button
          onClick={() => {
            handleViewSqlButtonClick();
            setData(tabData);
          }}
          className="bg-brand-ai-100 rounded-lg text-xs leading-5 font-inter-400 font-normal px-2 py-1"
        >
          View SQL
        </Button>
      </div>
    </div>
  );
};

const PlainTextTab = ({ tabData, isModal = false }) => {
  return (
    <>
      <div
        style={{ maxHeight: `${isModal ? "80vh" : "auto"}` }}
        className={`p-8 overflow-y-scroll scrollbar-hide ${!isModal ? "h-full" : "h-auto"}`}
      >
        <p dangerouslySetInnerHTML={{ __html: tabData?.values }} className="whitespace-pre-line"></p>
      </div>
    </>
  );
};

export { SQLTab, TableTab, CodeTab, ListTab, ERDiagramTab, PlainTextTab };
