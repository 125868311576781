import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import { useModal } from "../../../features/insightTemplates/components/ViewFlowDetailContext";
const handleStyle = { opacity: 0 };

export default memo(({ data }) => {
  const { openViewFlowDetailModal, setTabDetails } = useModal();

  const onClick = () => {
    setTabDetails(data?.nodeDetails?.tabs || []);
    openViewFlowDetailModal();
  };
  return (
    <div className="w-80 z-10">
      <Handle
        id="top"
        style={handleStyle}
        position={Position.Top}
        type="source"
      />
      <Handle
        id="right"
        style={handleStyle}
        position={Position.Right}
        type="source"
      />
      <Handle
        id="bottom"
        style={handleStyle}
        position={Position.Bottom}
        type="source"
      />
      <Handle
        id="left"
        style={handleStyle}
        position={Position.Left}
        type="source"
      />
      <div className="relative">
        <div
          style={{ boxShadow: " 0px 8px 24px 0px rgba(105, 112, 149, 0.10)" }}
          className="flex items-center justify-start p-2.5 bg-white font-inter-400 font-normal text-xs leading-5 text-brand-ai-200 rounded-xl w-full"
        >
          {data?.image?.url && (
            <div className="w-12">
              <img
                src={data.image.url}
                alt={data.image.alt}
                className="w-full"
              />
            </div>
          )}
          <div className="ml-2 w-fit">{data.label}</div>
        </div>
        {data.hasNodeDetails && (
          <div
            className="absolute -bottom-50 right-0 font-inter-500 font-medium text-xs leading-4 text-brand-ai-100"
            onClick={onClick}
          >
            {data?.nodeDetails?.text ? data?.nodeDetails?.text : "View Details"}
          </div>
        )}
      </div>
    </div>
  );
});
