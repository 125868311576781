import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { Spinner } from "@/components/Elements";
import { MainLayout } from "@/components/Layout";
import { lazyImport } from "@/utils/lazyImport";
import { ModalProvider as FlowchartNodeDetailModalProvider } from "@/features/insightTemplates/components/ViewFlowDetailContext";
import { NewInsightProvider } from "@/features/insightTemplates/components/NewInsightContext";
import { InsightFlowChart } from "@/features/InsightFlowChart";

const { Profile } = lazyImport(() => import("@/features/users"), "Profile");
const { Users } = lazyImport(() => import("@/features/users"), "Users");

const { Apps } = lazyImport(() => import("@/features/apps"), "Apps");
const { CreateNewApp } = lazyImport(
  () => import("@/features/apps"),
  "CreateNewApp"
);
const { EditApp } = lazyImport(() => import("@/features/apps"), "EditApp");
const { ChatApp } = lazyImport(() => import("@/features/apps"), "ChatApp");
const { InsightTemplates } = lazyImport(
  () => import("@/features/insightTemplates"),
  "InsightTemplates"
);
const { CreateNewInsight } = lazyImport(
  () => import("@/features/insightTemplates"),
  "CreateNewInsight"
);
const { ScheduledInsights } = lazyImport(
  () => import("@/features/scheduledInsights"),
  "ScheduledInsights"
);
const { Settings } = lazyImport(
  () => import("@/features/settings"),
  "Settings"
);
const { MyProfile } = lazyImport(
  () => import("@/features/settings"),
  "MyProfile"
);
const { UserManagement } = lazyImport(
  () => import("@/features/settings"),
  "UserManagement"
);
const { TeamDetail } = lazyImport(
  () => import("@/features/settings"),
  "TeamDetail"
);
const { DataSource } = lazyImport(
  () => import("@/features/dataSource"),
  "DataSource"
);
const { AddNewSource } = lazyImport(
  () => import("@/features/dataSource"),
  "AddNewSource"
);
const { EditDataSource } = lazyImport(
  () => import("@/features/dataSource"),
  "EditDataSource"
);
const { Executions } = lazyImport(
  () => import("@/features/executions"),
  "Executions"
);
const { Integrations } = lazyImport(
  () => import("@/features/integrations"),
  "Integrations"
);

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            {/* <Spinner size="xl" /> */}
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

const AppsPage = () => {
  return (
    <Apps>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </Apps>
  );
};

const CreateAppPage = () => {
  return (
    <CreateNewApp>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </CreateNewApp>
  );
};

const EditAppPage = () => {
  return (
    <EditApp>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </EditApp>
  );
};

const InsightTemplatesPage = () => {
  return (
    <FlowchartNodeDetailModalProvider>
      <InsightTemplates>
        <Suspense
          fallback={
            <div className="h-full w-full flex items-center justify-center">
              <Spinner size="xl" />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </InsightTemplates>
    </FlowchartNodeDetailModalProvider>
  );
};

const CreateNewInsightPage = () => {
  return (
    <NewInsightProvider>
      <FlowchartNodeDetailModalProvider>
        <CreateNewInsight>
          <Suspense
            fallback={
              <div className="h-full w-full flex items-center justify-center">
                <Spinner size="xl" />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </CreateNewInsight>
      </FlowchartNodeDetailModalProvider>
    </NewInsightProvider>
  );
};

const InsightFlowchartPage = () => {
  return (
    <FlowchartNodeDetailModalProvider>
      <InsightFlowChart>
        <Suspense
          fallback={
            <div className="h-full w-full flex items-center justify-center">
              <Spinner size="xl" />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </InsightFlowChart>
    </FlowchartNodeDetailModalProvider>
  );
};

const ScheduledInsightsPage = () => {
  return (
    <ScheduledInsights>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </ScheduledInsights>
  );
};

const DataSourcePage = () => {
  return (
    <DataSource>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </DataSource>
  );
};
const AddNewSourcePage = () => {
  return (
    <AddNewSource>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </AddNewSource>
  );
};
const EditDataSourcePage = () => {
  return (
    <EditDataSource>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </EditDataSource>
  );
};

const ExecutionPage = () => {
  return (
    <Executions>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </Executions>
  );
};

const IntegrationsPage = () => {
  return (
    <Settings>
      <Integrations>
        <Suspense
          fallback={
            <div className="h-full w-full flex items-center justify-center">
              <Spinner size="xl" />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </Integrations>
    </Settings>
  );
};
const MyProfilePage = () => {
  return (
    <Settings>
      <MyProfile>
        <Suspense
          fallback={
            <div className="h-full w-full flex items-center justify-center">
              <Spinner size="xl" />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </MyProfile>
    </Settings>
  );
};
const UserManagementPage = () => {
  return (
    <Settings>
      <UserManagement>
        <Suspense
          fallback={
            <div className="h-full w-full flex items-center justify-center">
              <Spinner size="xl" />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </UserManagement>
    </Settings>
  );
};
const TeamDetailPage = () => {
  return (
    <Settings>
      <TeamDetail>
        <Suspense
          fallback={
            <div className="h-full w-full flex items-center justify-center">
              <Spinner size="xl" />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </TeamDetail>
    </Settings>
  );
};

const ChatPage = () => {
  return (
    <FlowchartNodeDetailModalProvider>
      <ChatApp />
    </FlowchartNodeDetailModalProvider>
  );
};

export const protectedRoutes = [
  {
    path: "/admin",
    element: <App />,
    children: [
      // { path: "apps", element: <AppsPage /> },
      // { path: "app/create-new-app", element: <CreateAppPage /> },
      // { path: "app/:appId", element: <ChatApp /> },
      // { path: "app/edit/:appId", element: <EditAppPage /> },
      {
        path: "chat",
        element: <ChatPage />,
      },
      {
        path: "chat/create-new-insight",
        element: <CreateNewInsightPage />,
      },
      { path: "insight-templates", element: <InsightTemplatesPage /> },
      {
        path: "insight-templates/create-new-insight",
        element: <CreateNewInsightPage />,
      },
      { path: "schedule-insights", element: <ScheduledInsightsPage /> },
      { path: "settings", element: <IntegrationsPage /> },
      { path: "settings/my-profile", element: <MyProfilePage /> },
      { path: "settings/user-management", element: <UserManagementPage /> },
      { path: "settings/user-management/:teamId", element: <TeamDetailPage /> },
      // { path: "datasources", element: <DataSourcePage /> },
      // { path: "datasource/add-new-source", element: <AddNewSourcePage /> },
      // { path: "datasources/:datasourceId", element: <EditDataSourcePage /> },
      // { path: "executions", element: <ExecutionPage /> },
      { path: "users", element: <Users /> },
      { path: "profile", element: <Profile /> },
    ],
  },
  {
    path: "/app",
    children: [
      { path: "insight/:executionId", element: <InsightFlowchartPage /> },
    ],
  },
  { path: "*", element: <Navigate to="admin/chat" /> },
];
