import React, { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { FieldWrapper } from "./FieldWrapper";

export const TextAreaField = (props) => {
  const {
    label,
    className,
    error,
    placeholder,
    value,
    defaultValue,
    labelTextClassName,
    disabled = false,
    onChange,
    onKeyDown,
    onBlur,
    rows,
  } = props;

  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, [value]);

  return (
    <FieldWrapper label={label} error={error} className={labelTextClassName}>
      <textarea
        ref={textareaRef}
        defaultValue={defaultValue}
        placeholder={placeholder}
        value={value}
        className={twMerge(
          "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500",
          className
        )}
        disabled={disabled}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        rows={rows}
        style={{ resize: "none" }}
      />
    </FieldWrapper>
  );
};
