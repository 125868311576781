import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { API_ENV } from "./config";
import { DSN_SENTRY } from "@/config";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const container = document.getElementById("root");
const root = createRoot(container);

Sentry.init({
  dsn: DSN_SENTRY,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: API_ENV,
});

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
