import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import * as React from "react";

export const LayoutCol = React.forwardRef((props, ref) => {
  const { children, xl, sm, md, lg, xxl, span = 1, className,...remainingProps } = props;
  return (
    <div
     {...remainingProps}
      ref={ref}
      className={twMerge(
        `col-span-${span}`,
        sm && `sm:col-span-${sm}`,
        md && `md:col-span-${md}`,
        lg && `lg:col-span-${lg}`,
        xl && `xl:col-span-${xl}`,
        xxl && `xxl:col-span-${xxl}`,
        className
      )}
    >
      {children}
    </div>
  );
});
LayoutCol.propTypes = {
  children: PropTypes.node,
  xl: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xxl: PropTypes.number,
  gap: PropTypes.number,
  className: PropTypes.string,
};
