import { useQuery } from "react-query";
import { axios } from "@/lib/axios";
import { useNotificationStore } from "@/stores/notifications";

export const getFlowChart = (data) => {
  return axios.post(`index/api/v1/flowchart`, data);
};

export const useGetFlowChart = (data) => {
  const { addNotification } = useNotificationStore();

  return useQuery(["flowChart"], () => getFlowChart(data), {
    onError: (error) => {
      addNotification({ type: "error", title: error.message });
    },
  });
};