import * as React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { useNavigate } from "react-router-dom";

export const Table = React.forwardRef(
  (
    {
      className,
      rows,
      columns,
      headerClassName,
      columnClass,
      rowClassName,
      onRowClick,
      isDataSource = true,
      style,
    },
    ref
  ) => {
    const navigate = useNavigate();

    const handleRowClick = (dataSourceId) => {
      if (isDataSource === true) {
        navigate(`/admin/datasources/${dataSourceId}`);
      } else {
        // Schedule Insights path
      }
    };

    return (
      <div className="w-full border rounded-lg overflow-x-auto">
        <table
          ref={ref}
          style={style}
          className={twMerge("min-w-full divide-y divide-gray-200", className)}
        >
          <thead className={twMerge("bg-gray-50", headerClassName)}>
            <tr>
              {columns.map((column, columnIndex) => (
                <th
                  key={column}
                  scope="col"
                  className={twMerge(
                    "px-6 py-3 text-center text-xs font-medium text-gray-500 tracking-wider",
                    columnClass,
                    columnIndex === 0 && "rounded-tl-lg",
                    columnIndex === columns.length - 1 && "rounded-tr-lg"
                  )}
                >
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {rows.map((row, rowIndex) => (
              <tr
                className="hover:bg-brand-ai-700 cursor-pointer"
                key={rowIndex}
                onClick={() => handleRowClick(row.id)}
              >
                {columns.map((column, columnIndex) => (
                  <td
                    key={columnIndex}
                    className={twMerge(
                      "px-14 py-4 text-center whitespace-nowrap text-sm text-brand-ai-600",
                      rowClassName
                    )}
                  >
                    {row[column]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
);
Table.displayName = "Table";

Table.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.array,
  columns: PropTypes.array,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
};
