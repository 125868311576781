import * as React from "react";

import logo from "@/assets/petavue-logo.svg";
import { Link } from "@/components/Elements";
import { Head } from "@/components/Head";

export const Layout = ({
  children,
  title,
  describtion,
  describtionClassName,
  showLogo = true,
}) => {
  return (
    <>
      <Head title={title} />
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div
          style={{ boxShadow: "0px 8px 24px 0px rgba(105, 112, 149, 0.15)" }}
          className="mt-8 bg-white py-8 px-4 sm:rounded-xl sm:mx-auto sm:w-full sm:max-w-md"
        >
          <div className="sm:mx-auto mb-4 sm:w-full sm:max-w-md">
            {showLogo && (
              <div className="flex justify-center">
                <Link className="flex items-center text-white" to="/">
                  <img className="h-8 w-auto" src={logo} alt="Workflow" />
                </Link>
              </div>
            )}

            <h2 className="mt-4 text-center text-xl font-inter-600 font-semibold text-brand-ai-200 leading-8">
              {title}
            </h2>
            <p
              className={`mt-2 ${describtionClassName} leading-6 text-base font-inter-500 font-medium text-brand-ai-gray-100`}
            >
              {describtion}
            </p>
          </div>

          <div className="px-4 sm:px-10 mt-6">{children}</div>
        </div>
      </div>
    </>
  );
};
