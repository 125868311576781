// Context for ViewFlowDetailModal
import React, { createContext, useContext, useState } from "react";
import { ViewFlowDetailModal } from "./ViewFlowDetailModal";

const ModalContext = createContext();

export function ModalProvider({ children }) {
  const [isViewFlowDetailModalOpen, setIsViewFlowDetailModalOpen] =
    useState(false);

  const [tabDetails, setTabDetails] = useState([]);
  const [step, setStep] = useState(1);
  const [data, setData] = useState([]);

  const openViewFlowDetailModal = () => {
    setIsViewFlowDetailModalOpen(true);
  };

  const closeViewFlowDetailModal = () => {
    setIsViewFlowDetailModalOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        isViewFlowDetailModalOpen,
        openViewFlowDetailModal,
        closeViewFlowDetailModal,
        tabDetails,
        setTabDetails,
        step,
        setStep,
        data,
        setData,
      }}
    >
      {children}
      <ViewFlowDetailModal transition={false} />
    </ModalContext.Provider>
  );
}

export function useModal() {
  return useContext(ModalContext);
}
