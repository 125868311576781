import React, { useEffect } from "react";
import ReactFlowWrapper from "@/components/FlowComponent/ReactFlowChart";
import { useGetFlowChart } from "../api/getFlowChart";
import { useParams } from "react-router-dom";
import { Spinner } from "@/components/Elements";

export const InsightFlowChart = () => {
  const { executionId } = useParams();

  const appQuery = useGetFlowChart({ execution_id: executionId, app_id: "124" });

  if (appQuery.isFetching || appQuery.isLoading) {
    return <div className="w-full h-48 flex justify-center items-center">
      <Spinner size="lg" />
    </div>
  }
  return (
    <ReactFlowWrapper initialNodes={appQuery?.data?.flowchart?.nodes} initialEdges={appQuery?.data?.flowchart?.edges} key={appQuery?.data?.flowchart?.nodes?.length} />
  );
};
