import React from "react";
import { twMerge } from "tailwind-merge";
import { CaretUp, CaretDown } from "phosphor-react";

export const Accordion = ({
  title,
  children,
  className,
  headerClassName,
  wrapperClassName,
  isOpen,
  handleToggle,
}) => {
  return (
    <div className={twMerge("border rounded-md", wrapperClassName)}>
      <button
        className={twMerge(
          "flex items-center justify-between w-full px-4 py-2 bg-gray-100 hover:bg-gray-200 focus:outline-none",
          className
        )}
        onClick={handleToggle}
      >
        <h2 className={twMerge("text-lg font-medium", headerClassName)}>
          {title}
        </h2>
        {isOpen ? <CaretUp size={14} /> : <CaretDown size={14} />}
      </button>
      {isOpen && <div className="text-gray-700">{children}</div>}
    </div>
  );
};
