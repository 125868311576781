import { useState } from "react";
import { Button, Modal } from "@/components/Elements";
import { Tabs } from "@/components/Elements/Tabs";
import { Tab } from "@/components/Elements/Tabs";
import { ViewFlowDetailData } from "../DUMMY_DATA/ViewFlowDetailData";
import { useModal } from "./ViewFlowDetailContext";
import ReactFlowWrapper from "@/components/FlowComponent/ReactFlowChart";
import { nodes, edges } from "../DUMMY_DATA/ERDiagramData";
import { CodeTab, SQLTab, ListTab, TableTab, ERDiagramTab, PlainTextTab } from "./ExecutionOutputTab";


const sqlCode = `  SELECT A.ID AS AccountID, A.NAME AS AccountName, U1.FIRSTNAME AS AccountOwnerFirstName, U1.LASTNAME AS AccountOwnerLastName, 
U1.EMAIL AS AccountOwnerEmail, O.AMOUNT AS OpportunityValue, U2.FIRSTNAME AS OpportunityOwnerFirstName, U2.LASTNAME AS
OpportunityOwnerLastName, U2.EMAIL AS OpportunityOwnerEmail, O.CLOSEDATE AS OpportunityCloseDate 
FROM Salesforce_Accounts AS A 
LEFT JOIN Salesforce_Users AS U1 ON A.OWNERID = U1.ID 
LEFT JOIN Salesforce_Opportunities AS 0 ON A.ID = 0.ACCOUNTID 
LEFT JOIN Salesforce_Users AS U2 ON 0.0WNERID = U2.ID 
WHERE O.STAGENAME = 'Closed Won' AND O.CLOSEDATE >= DATE_SUB(CURDATE), INTERVAL 270 DAY) AND O.CLOSEDATE <=DATE_SUB(CURDATE(), 
INTERVAL 365 DAY)`;

const Dummy_sql_data = {
  sql: sqlCode,
  sql_description: [
    {
      header: "SELECT Clause",
      text: `<ul className="text-sm font-inter-400 font-normal leading-5 tracking-wide">
      <li>
        <strong>AID AS AccountID: </strong>This renames the ID
        column from the Salesforce_Accounts table to AccountID.
      </li>
      <li>
        <strong> A NAME AS AccountName:</strong> This renames the
        NAME column from the Salesforce_Accounts table to
        AccountName.
      </li>
      <li>
        <strong> U1.FIRSTNAME AS AccountOwnerFirstName:</strong>{" "}
        Renames the FIRSTNAME column from the Salesforce_Users table
        (aliased as U1) to AccountOwnerFirstName
      </li>
      <li>
        <strong> U1.LASTNAME AS AccountOwnerLastName:</strong>{" "}
        Renames the LASTNAME column from the Salesforce_Users table
        (aliased as U1) to AccountOwnerLastName.
      </li>
      <li>
        <strong> U1.EMAIL AS AccountOwnerEmail:</strong> Renames the
        EMAIL column from the Salesforce _Users table (aliased as
        U1) to AccountOwnerEmail.
      </li>
      <li>
        <strong> O.AMOUNT AS OpportunityValue:</strong> Renames the
        AMOUNT column from the Salesforce_Opportunities table
        (aliased as 0) to Opportunity Value
      </li>
      <li>
        <strong> U2.FIRSTNAME AS Opportunity0wnerFirstName:</strong>{" "}
        Renames the FIRSTNAME column from the Salesforce_Users table
        (aliased as U2) to Opportunity0wnerFirstName.
      </li>
      <li>
        <strong>U2.LASTNAME AS OpportunityOwnerLastName:</strong>{" "}
        Renames the LASTNAME column from the Salesforce_Users table
        (aliased as U2) to Opportunity0wnerLastName.
      </li>
      <li>
        <strong> U2.EMAIL AS Opportunity0wnerEmail:</strong> Renames
        the EMAIL column from the Salesforce_Users table (aliased as
        U2) to OpportunityOwnerEmail.
      </li>
      <li>
        <strong> O.CLOSEDATE AS OpportunityCloseDate:</strong>{" "}
        Renames the CLOSEDATE column from the
        Salesforce_Opportunities table (aliased as 0) to
        OpportunityCloseDate
      </li>
    </ul>`,
    },
    {
      header: "FROM Clause",
      text: `<ul className="text-sm font-inter-400 font-normal leading-5 tracking-wide">
      <li>
        <strong> Salesforce_Accounts AS A:</strong> Specifies the
        Salesforce_Accounts table and aliases it as A.
      </li>
      <li>
        <strong>
          LEFT JOIN Salesforce_Users AS U1 ON A.OWNERID = U1.ID:
        </strong>{" "}
        Joins the Salesforce_Users table (aliased as U1) based on
        the OWNERID column of the Salesforce_Accounts table and the
        ID column of the Salesforce_Users table.
      </li>
      <li>
        <strong>
          LEFT JOIN Salesforce_Opportunities AS O ON A.ID =
          O.ACCOUNTID:
        </strong>
        Joins the Salesforce_Opportunities table (aliased as 0)
        based on the ID column of the Salesforce_Accounts table and
        the ACCOUNTID column of the Salesforce_Opportunities table.
      </li>
      <li>
        <strong>
          LEFT JOIN Salesforce_Users AS U2 ON 0.OWNERID = U2.ID:
        </strong>{" "}
        Joins the Salesforce_Users table (aliased as U2) based on
        the OWNERID column of the Salesforce_Opportunities table and
        the ID column of the Salesforce_Users table.
      </li>
    </ul>`,
    },
    {
      header: "WHERE Clause",
      text: `<ul className="text-sm font-inter-400 font-normal leading-5 tracking-wide">
      <li>
        <strong> O.STAGENAME = 'Closed Won': </strong>Filters the
        results to include only records where the value in the
        STAGENAME column of the Salesforce_Opportunities table is
        equal to “Closed Won”
      </li>
    </ul>`,
    },
  ],
};

const Dummy_table_data = {
  headers: [
    "AccountID",
    "AccountName",
    "Account OwnerFirstName",
    "Account OwnerLastName",
    "Account OwnerEmail",
    "Opportunity Value",
    "Opportunity OwnerFirstName",
    "Opportunity OwnerLastName",
    "Opportunity OwnerEmail",
    "Opportunity CloseDate",
  ],
  values: ViewFlowDetailData.map((row, rowIndex) => ({
    AccountID: row.accountID,
    AccountName: row.accountName,
    "Account OwnerFirstName": row.accountOwnerFirstName,
    "Account OwnerLastName": row.accountOwnerLastName,
    "Account OwnerEmail": row.accountOwnerEmail,
    "Opportunity Value": row.opportunityValue,
    "Opportunity OwnerFirstName": row.opportunityOwnerFirstName,
    "Opportunity OwnerLastName": row.opportunityOwnerLastName,
    "Opportunity OwnerEmail": row.opportunityOwnerEmail,
    "Opportunity CloseDate": row.opportunityOwnerCloseData,
  })),
};


export const GetOutputTabContent = ({ tabData, index = 1, tablePageClassName = "justify-center", itemsPerPage = 15, tableClassName = "bg-transparent", isModal = false }) => {
  return <>
    {tabData?.type === "details" && tabData.title === "CODE" && <CodeTab tabData={tabData} key={`${tabData.title}-${index}`} />}
    {(tabData?.type === "details" && tabData.title === "SQL" && tabData.er_diagram) && <ERDiagramTab tabData={tabData} />}
    {(tabData?.type === "details" && tabData.title === "SQL" && !tabData.er_diagram) && <SQLTab tabData={tabData} key={`${tabData.title}-${index} `} showBack={false} />}
    {/* {(tabData?.type === "details" && tabData.title === "SQL") && <SQLTab tabData={tabData} key={`${tabData.title}-${index} `} showBack={false} />} */}
    {(tabData?.type === "Table") && <TableTab tabData={tabData} tablePageClassName={tablePageClassName} itemsPerPage={itemsPerPage} tableClassName={tableClassName} />}
    {(tabData?.type === "List") && <ListTab tabData={tabData} isModal={isModal} />}
    {(tabData?.type === "Plain Text") && <PlainTextTab tabData={tabData} isModal={isModal} />}
  </>
}



export const ViewFlowDetailModal = ({ transition = true }) => {
  const { isViewFlowDetailModalOpen, closeViewFlowDetailModal, tabDetails, step, setStep, data } =
    useModal();
  const [initialNodes, setInitialNode] = useState([]);
  const [initialEdges, setInitialEdges] = useState([]);

  const handleViewSqlButtonClick = () => {
    setStep((prevStep) => prevStep + 1);
  };
  const goBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <Modal
      isOpen={isViewFlowDetailModalOpen}
      onClose={() => {
        step === 1 && closeViewFlowDetailModal();
        setStep(1);
      }}
      headerClassName="px-0 py-0"
      className="w-4/5 relative"
      closeClassName={"top-4"}
      transition={transition}
    >
      {step === 1 &&
        (tabDetails && tabDetails.length > 0 ? (
          <>
            <Tabs
              tabNavigatorGroupClassName="flex justify-around bg-white rounded-none w-full border-b-2 mb-4 mt-2"
              tabNavigatorButtonClassName="rounded-none whitespace-nowrap border-brand-ai-200 focus:ring-0 justify-center text-sm"
            >
              {tabDetails.map((tabData, index) => (
                <Tab
                  id={index}
                  title={tabData?.title}
                  className={"text-sm flex flex-col justify-between"}
                  key={index}
                >
                  <GetOutputTabContent tabData={tabData} index={index} key={index} isModal={true} />
                  {/* {tabData?.type === "details" && tabData.title === "CODE" && <CodeTab tabData={tabData} key={`${tabData.title}-${index}`} />}
                  {tabData?.type === "details" && tabData.title === "SQL" && <SQLTab tabData={tabData} key={`${tabData.title}-${index}`} />}
                  {(tabData?.type === "Table") && <TableTab tabData={tabData} />}
                  {(tabData?.type === "List") && <ListTab tabData={tabData} />} */}
                </Tab>
              ))}
            </Tabs>
          </>
        ) : (
          <Tabs
            tabNavigatorGroupClassName="flex justify-around bg-white rounded-none w-full border-b-2 mb-4 mt-2"
            tabNavigatorButtonClassName="rounded-none whitespace-nowrap border-brand-ai-200 focus:ring-0 justify-center text-sm"
          >
            <Tab
              id={0}
              title="Details"
              className={"text-sm flex flex-col justify-between"}
            >
              {/* <div className="relative">
                <ReactFlowWrapper
                  key={initialNodes.length}
                  initialNodes={nodes}
                  initialEdges={edges}
                  direction="LR"
                  className={"h-96"}
                />
                <div className="absolute left-6 bottom-3">
                  <Button
                    onClick={handleViewSqlButtonClick}
                    className="bg-brand-ai-100 rounded-lg text-xs leading-5 font-inter-400 font-normal px-2 py-1"
                  >
                    View SQL
                  </Button>
                </div>
              </div> */}
              <SQLTab tabData={Dummy_sql_data} showBack={false} />
            </Tab>
            <Tab id={11} title="Output" className={"text-sm"}>
              <TableTab tabData={Dummy_table_data} />
            </Tab>
          </Tabs>
        ))}
      {step === 2 && <SQLTab tabData={data} goBack={goBack} />}
    </Modal >
  );
};
