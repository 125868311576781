import { useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Dropdown, DropdownItem } from "../Dropdown";
import { Table } from "@/components/Elements";
import { twMerge } from "tailwind-merge";

export const TableWithPagination = ({
  itemsPerPage = 2,
  items = [],
  pageRangeDisplayed = 2,
  onPageChange = () => {},
  marginPagesDisplayed = 2,
  className,
  rowClassName,
  headerClassName,
  columnClass,
  displayShowButton = true,
  columns,
  style,
  tablePageClassName,
}) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [curItemsPerPage, setCurItemsPerPage] = useState(itemsPerPage);
  const endOffset = itemOffset + curItemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / curItemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * curItemsPerPage) % items.length;
    setItemOffset(newOffset);
    onPageChange(event);
  };
  const pageRanges = [5];
  for (let i = 1; i * 20 < items.length - 1; i++) {
    pageRanges.push(20 * i);
  }
  pageRanges.push(items.length);
  const handleItemsPerPage = (event) => {
    setCurItemsPerPage(event.target.innerText);
  };

  const refT = useRef();
  return (
    <>
      {columns && (
        <Table
          ref={refT}
          style={style}
          columns={columns}
          rows={currentItems}
          headerClassName={
            headerClassName ? headerClassName : "bg-brand-ai-100"
          }
          columnClass={twMerge(
            "text-white text-xs p-2 font-inter-500 font-medium",
            columnClass
          )}
          rowClassName={twMerge(
            "font-inter-400 font-normal text-xs border px-1 py-2",
            rowClassName
          )}
          isDataSource={false}
          key={`${currentItems.length}-${endOffset}`}
        />
      )}
      <div className={clsx("w-full pt-4", "bg-transparent", className)}>
        <div
          className={clsx(
            "flex",
            "justify-end items-center",
            tablePageClassName
          )}
        >
          {/* <div
            className={clsx("text-sm text-t-medium")}
          >{`${currentItems.length} of ${items.length} items`}</div> */}
          <ReactPaginate
            className={clsx(
              "flex",
              "gap-2",
              "font-normal",
              "list-none",
              "item-center"
            )}
            marginPagesDisplayed={marginPagesDisplayed}
            breakLabel="..."
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={pageRangeDisplayed}
            pageCount={pageCount}
            previousLabel="< Prev"
            nextClassName="text-xs m-auto text-brand-ai-100"
            previousClassName="text-xs m-auto text-brand-ai-100"
            disabledLinkClassName="text-brand-ai-gray-100"
            renderOnZeroPageCount={null}
            pageClassName={clsx("rounded-md text-xs")}
            activeClassName={clsx("bg-brand-ai-50 bg-opacity-60 font-medium")}
            pageLinkClassName={clsx(
              "w-7",
              "text-center text-brand-ai-200",
              "flex justify-center items-center",
              "h-7"
            )}
          />
          {displayShowButton && (
            <div className={clsx("flex gap-2 text-sm items-center")}>
              Show
              <Dropdown
                size="sm"
                title={`${curItemsPerPage} Rows`}
                headerClasses="text-brand-primary-400 hover:bg-brand-gray-100 text-sm"
              >
                {pageRanges.map((pageRange) => (
                  <DropdownItem
                    children={pageRange}
                    onClick={(event) => handleItemsPerPage(event)}
                  />
                ))}
              </Dropdown>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

// TableWithPagination.propTypes = {
//   itemsPerPage: PropTypes.number,
//   items: PropTypes.array.isRequired,
//   pageRangeDisplayed: PropTypes.number,
//   onPageChange: PropTypes.func,
//   marginPagesDisplayed: PropTypes.number,
//   className: PropTypes.string,
//   // PaginationItems: PropTypes.element.isRequired
// };
