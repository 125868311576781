import PropTypes from "prop-types";
import * as React from "react";
import { twMerge } from "tailwind-merge";
import { Spinner } from "@/components/Elements/Spinner";

const variants = {
  primary: "bg-brand-primary-600 text-white",
  inverse:
    "bg-white text-brand-primary-600 border-brand-primary-600 rounded-lg",
  danger: "bg-brand-error-600 text-white rounded-lg",
};

const sizes = {
  xs: "py-2 px-4 text-xs",
  sm: "py-2 px-6 text-sm",
  md: "py-2 px-8 text-md",
  lg: "py-2 px-10 text-lg",
  xl: "py-2 px-16 text-lg",
};

export const Button = React.forwardRef(
  (
    {
      type = "button",
      className = "",
      variant = "primary",
      size = "md",
      isLoading = false,
      disabled = false,
      onClick,
      startIcon,
      endIcon,
      childrenWrapperClassName,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={twMerge(
          `flex justify-center items-center border border-transparent`,
          `shadow-sm font-medium focus:outline-none hover:opacity-80`,
          variants[variant],
          disabled ? "bg-state-disabled-dark cursor-not-allowed bg-opacity-60" : "",
          sizes[size],
          className
        )}
        {...props}
      >
        {isLoading && <Spinner size="sm" className="text-current" />}
        {!isLoading && startIcon}
        <span className={twMerge("mx-2", childrenWrapperClassName)}>
          {props.children}
        </span>
        {!isLoading && endIcon}
      </button>
    );
  }
);

Button.displayName = "Button";

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  variant: PropTypes.oneOf(["primary", "inverse", "danger"]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
};
