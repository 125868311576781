import { Link } from "react-router-dom";
import * as z from "zod";

import { Button } from "@/components/Elements";
import { Form, InputField } from "@/components/Form";
import { useAuth } from "@/lib/auth";

const schema = z.object({
  email: z.string().min(1, "Required"),
  password: z.string().min(1, "Required"),
});

export const ForgetPasswordForm = ({ onSuccess }) => {
  const { login, isLoggingIn } = useAuth();

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          if (isLoggingIn) return;
          await login(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email"
              labelTextClassName="flex flex-col gap-2"
              className="rounded-xl"
              placeholder="email@company.com"
              error={formState.errors["email"]}
              registration={register("email")}
            />
            <div>
              <Button
                isLoading={isLoggingIn}
                type="submit"
                className="w-full rounded-xl bg-brand-ai-100 btn-style font-inter-500 text-sm font-medium"
              >
                Request Reset Link
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-6 flex items-center justify-center">
        <div className="text-sm">
          <Link
            to="../login"
            className="font-medium font-inter-500 text-brand-ai-100 hover:text-blue-500"
          >
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
};
