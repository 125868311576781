/**
 * For user flow implementation,
 * Refer https://tailwindui.com/components/application-ui/elements/dropdowns
 */

import React, { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { CaretDown, CaretRight } from "phosphor-react";
import { twMerge } from "tailwind-merge";
import { Spinner } from "../Spinner";
import PropTypes from "prop-types";

const DropdownSizes = {
  lg: "text-lg font-semibold gap-4",
  md: "text-sm font-semibold gap-2",
  sm: "text-xs font-semibold gap-1",
};
const DropdownItemSizes = {
  lg: "text-lg font-semibold shadow-lg rounded-lg",
  md: "text-md font-semibold shadow-md rounded-md",
  sm: "text-sm font-semibold shadow-sm rounded-sm",
};

export const Dropdown = ({
  isLoading,
  children,
  title,
  className,
  dropDownButtonWrapperClassName,
  headerClasses,
  HeaderIcon,
  replaceTitle = true,
  size = "md",
  showCaretIcon = true,
  showCaretDown = true,
  dropDownChildrenClassName,
}) => {
  const [selectedOption, setSelectedOption] = useState(title);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  return (
    <Menu as="div" className={twMerge("w-max", className)}>
      <Menu.Button
        className={twMerge(
          "inline-flex items-center w-full px-4 py-1.5 font-medium tracking-wide rounded-sm",
          headerClasses ||
            "text-t-highlight  border-outline-extra-light bg-state-active-white hover:bg-brand-gray-400",
          DropdownSizes[size],
          dropDownButtonWrapperClassName
        )}
      >
        {HeaderIcon}
        {isLoading && (
          <Spinner
            variant="primary"
            size="sm"
            className="text-current -ml-1 mr-2"
          />
        )}
        {replaceTitle ? selectedOption : title}
        {!isLoading &&
          showCaretIcon &&
          (showCaretDown ? (
            <CaretDown className="h-4" aria-hidden="true" />
          ) : (
            <CaretRight className="h-4" aria-hidden="true" />
          ))}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={twMerge(
            "absolute",
            "z-20",
            "origin-top-right",
            "mt-2",
            "bg-white ring-1",
            "ring-black",
            "ring-opacity-5",
            "focus:outline-none",
            DropdownItemSizes[size],
            dropDownChildrenClassName
          )}
        >
          {replaceTitle ? (
            <div className="flex flex-col gap-3 text-brand-ai-200 cursor-pointer">
              {React.Children.map(children, (child) => {
                return (
                  <Menu.Item>
                    {({ close }) =>
                      React.cloneElement(child, {
                        onClick: () => {
                          handleOptionSelect(child.props.children);
                          if (
                            replaceTitle &&
                            typeof child?.props?.onClick === "function"
                          ) {
                            child?.props?.onClick();
                            close();
                          }
                        },
                      })
                    }
                  </Menu.Item>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-col text-brand-ai-200 cursor-pointer">
              {children}
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

Dropdown.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.node,
  className: PropTypes.string,
  headerClasses: PropTypes.string,
  HeaderIcon: PropTypes.element,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  showCaretIcon: PropTypes.bool,
  dropDownChildrenClassName: PropTypes.string,
  dropDownButtonWrapperClassName: PropTypes.string,
};
