import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import * as React from "react";

export const LayoutRow = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    xl,
    sm,
    md,
    lg,
    xxl,
    gap = 0,
    cols = 5,
    span = 1,
    ...remainingProps
  } = props;
  return (
    <div
      ref={ref}
      {...remainingProps}
      className={twMerge(
        `grid`,
        `gap-${gap}`,
        `grid-cols-${cols}`,
        sm && `sm:grid-cols-${sm}`,
        md && `md:grid-cols${md}`,
        lg && `lg:grid-cols${lg}`,
        xl && `xl:grid-cols${xl}`,
        xxl && `xxl:grid-cols${xxl}`,
        `row-span-${span}`,
        className
      )}
    >
      {children}
    </div>
  );
});
LayoutRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  xl: PropTypes.number,
  span: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xxl: PropTypes.number,
  gap: PropTypes.number,
  cols: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
