import { forwardRef, useState } from "react";
import { LayoutRow, LayoutCol } from "@/components/Elements/Layout";
import { Sidebar } from "./Sidebar";

export const MainLayout = forwardRef((props, ref) => {
  const { children } = props;
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <>
      <div className="flex">
        <div className="h-screen transition-all duration-300">
          <Sidebar
            isCollapsed={isSidebarCollapsed}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <LayoutRow cols={10} className="h-full w-full">
          {/* <LayoutCol
            span={isSidebarCollapsed ? 1 : 2}
            className={`bg-white h-screen transition-all duration-300`}
          >
          </LayoutCol> */}
          <LayoutCol
            span={10}
            className={`overflow-y-auto bg-brand-layout-light transition-all duration-300`}
          >
            {children}
          </LayoutCol>
        </LayoutRow>
      </div>
    </>
  );
});
