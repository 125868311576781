import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { FieldWrapper } from "./FieldWrapper";
import { Eye, EyeSlash } from "phosphor-react";

export const InputField = (props) => {
  const {
    type = "text",
    label,
    className,
    registration,
    error,
    placeholder,
    defaultValue,
    name,
    onChange,
    value,
    disabled = false,
    labelTextClassName,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <FieldWrapper label={label} error={error} className={labelTextClassName}>
      <div className="relative">
        <input
          onChange={onChange}
          disabled={disabled}
          name={name}
          type={showPassword ? "text" : type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          className={twMerge(
            "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500",
            className
          )}
          {...registration}
          value={value}
        />
        {type === "password" && (
          <div
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <Eye size={20} /> : <EyeSlash size={20} />}
          </div>
        )}
      </div>
    </FieldWrapper>
  );
};
