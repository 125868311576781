import { useNavigate } from "react-router-dom";

import { Layout } from "../components/Layout";
import { ForgetPasswordForm } from "../components/ForgetPasswordForm";

export const ForgetPassword = () => {
  const navigate = useNavigate();

  return (
    <Layout
      showLogo={false}
      title="Forget Password"
      describtion="Enter the email address where you would like the reset information to be sent."
      describtionClassName="text-left mx-10"
    >
      <ForgetPasswordForm onSuccess={() => navigate("/admin")} />
    </Layout>
  );
};
