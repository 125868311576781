import { NavLink, useLocation } from "react-router-dom";
import {
  Bell,
  Wrench,
  DotsNine,
  File,
  Megaphone,
  BookOpen,
  Database,
  CaretDoubleLeft,
  CaretDoubleRight,
  Lightbulb,
  Alarm,
  Plugs,
  Gear,
  SignOut,
} from "phosphor-react";
import { twMerge } from "tailwind-merge";
import logo from "@/assets/logo.png";
import { Tooltip } from "../Elements/Tooltip";
import { BookOpenIcon } from "@heroicons/react/outline";
import { useAuth } from "@/lib/auth";

const NavigationStyles =
  "border-none rounded-xl shadow-none group flex justify-start items-center px-2 py-2 font-inter-400";

export const Sidebar = ({ isCollapsed, toggleSidebar }) => {
  const location = useLocation();
  const { logout } = useAuth();

  const navigation = [
    {
      name: "Chat",
      to: `chat`,
      icon: Lightbulb,
    },
    {
      name: "Insight Templates",
      to: `insight-templates`,
      icon: BookOpenIcon,
    },
    {
      name: "Schedule Insights",
      to: `schedule-insights`,
      icon: Alarm,
    },
    // {
    //   name: "Data Sources",
    //   to: `datasources`,
    //   icon: Database,
    // },
    // {
    //   name: "Executions",
    //   to: `executions`,
    //   icon: BookOpen,
    // },
    // {
    //   name: "Integrations",
    //   to: `integrations`,
    //   icon: Plugs,
    // },
    {
      name: "Settings",
      to: `settings`,
      icon: Gear,
    },
  ];

  const extras = [
    {
      name: "Logout",
      // to: `learn-more`,
      icon: SignOut,
      onClick: () => logout()
    },
    // {
    //   name: "Announcements",
    //   to: `announcements`,
    //   icon: Megaphone,
    // },
    // {
    //   name: "Notifications",
    //   to: `notifications`,
    //   icon: Bell,
    // },
  ];
  const isLinkActive = (linkText) => {
    return location.pathname.includes(`admin/${linkText}`);
  };
  return (
    <section
      className={`border-r border-outline-light flex flex-col justify-between h-full bg-white ${isCollapsed ? "w-16" : "w-64"
        } duration-300 transition-width h-full`}
    >
      <div className={`flex flex-col ${isCollapsed ? "p-1" : "p-4"} h-full`}>
        {isCollapsed ? (
          // <PetavueIcon />
          <img src={logo} alt="" width={35} className="mx-auto mt-3" />
        ) : (
          <div className="flex justify-between items-center">
            <span className="flex flex-row gap-4 items-center">
              {/* <PetavueIcon /> */}
              <img src={logo} alt="" width={40} />
              <h2 className="leading-7 font-inter-700 font-bold">Petavue</h2>
            </span>
            <button
              className={`text-brand-dark-gray-400 ${isCollapsed && "border-t-2 border-b-2"
                }`}
              onClick={toggleSidebar}
            >
              <CaretDoubleLeft size={24} />
            </button>
          </div>
        )}
        {/* {isCollapsed && (
          <div className="border-t-2 border-b-2 flex justify-center my-3 py-4">
            <button
              className={`text-brand-dark-gray-400 `}
              onClick={toggleSidebar}
            >
              <CaretDoubleRight size={20} />
            </button>
          </div>
        )} */}

        <div
          className={`border-t my-3 pt-4 flex flex-col ${isCollapsed ? " items-center" : "mt-7"
            } duration-300 justify-between flex-grow`}
        >
          <div className="flex flex-col gap-4 pb-6">
            {navigation?.map((item, index) => (
              <NavLink
                end={index === 0}
                key={item.name}
                to={item.to}
                className={({ isActive }) =>
                  isActive || isLinkActive(item.to)
                    ? `${NavigationStyles} hover:text-brand-ai-200 font-inter-600 hover:bg-brand-ai-700 text-white bg-brand-ai-100 w-full`
                    : `${NavigationStyles} bg-transparent text-brand-dark-gray-400 hover:text-brand-ai-200 hover:bg-brand-ai-700 w-full`
                }
              >
                <div className="relative">
                  <div className="flex gap-4 items-center">
                    <item.icon
                      className={twMerge(
                        `group-hover:text-brand-ai-200`,
                        location.pathname.includes(`/admin/${item.to}`)
                          ? "text-white"
                          : "text-gray-400",
                        `flex-shrink-0 h-5 w-5 text-sm`
                      )}
                      aria-hidden="true"
                      fontSize={24}
                    />
                    {!isCollapsed && item.name}
                  </div>
                  <div className="absolute -top-1 z-10 left-full ml-3.5 px-3 py-1 bg-white leading-5 font-inter-400 font-normal text-brand-ai-200 text-xs hidden group-hover:block rounded-lg border border-solid border-brand-ai-700 card-shadow whitespace-nowrap">
                    {item.name}
                  </div>
                </div>
              </NavLink>
            ))}
          </div>
          <div className="flex flex-col gap-4">
            {extras?.map((item, index) => (
              <button
                end={index === 0}
                key={item.name}
                className={`${NavigationStyles} font-inter-600 hover:bg-brand-ai-700 text-brand-ai-100 w-full`}
                onClick={item?.onClick}
              >
                <div className="relative">
                  <div className="flex gap-4 items-center">
                    <item.icon
                      className={twMerge(
                        `group-hover:text-brand-ai-200 text-brand-ai-gray-100`,
                        `flex-shrink-0 h-5 w-5 text-sm`
                      )}
                      aria-hidden="true"
                      fontSize={24}
                    />
                    {!isCollapsed && item.name}
                  </div>
                  <div className="absolute -top-1 z-10 left-full ml-3.5 px-3 py-1 bg-white leading-5 font-inter-400 font-normal text-brand-ai-200 text-xs hidden group-hover:block rounded-lg border border-solid border-brand-ai-700 card-shadow whitespace-nowrap">
                    {item.name}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className={`${isCollapsed && "block"}`}>
        <div className="flex gap-4 px-4 py-2 text-brand-dark-gray-400">
          <img src={logo} alt="" width={40} />
          {!isCollapsed && (
            <span className="flex flex-col gap-1">
              <h2>Prasanna V.</h2>
              <p className="text-xs">pv@petavue.com</p>
            </span>
          )}
        </div>
      </div>
    </section>
  );
};
