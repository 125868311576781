import Axios from "axios";

import { FLOWCHART_API_URL } from "@/config";
import { useNotificationStore } from "@/stores/notifications";
import { queryClient } from "@/lib/react-query";
import storage from "@/utils/storage";

function authRequestInterceptor(config) {
  const token = storage.getToken();
  if (token) {
    config.headers.authorization = 'Bearer ' + token;
  }
  config.headers.Accept = "application/json";
  return config;
}

export const axios = Axios.create({
  baseURL: FLOWCHART_API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error?.response?.data?.message || error?.message;

    if (message === "Wrong authentication token") {
      storage.clearToken();
      queryClient.clear();
      window.location.assign(window.location.origin);
    }

    useNotificationStore.getState().addNotification({
      type: "error",
      title: "Error",
      message,
    });

    return Promise.reject(error);
  }
);
