import { Navigate, useRoutes } from "react-router-dom";

import { Landing } from "@/features/misc";
import { useAuth } from "@/lib/auth";

import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";
import { MainLayout } from "@/components/Layout";

export const AppRoutes = () => {
  const auth = useAuth();

  const commonRoutes = [{ path: "/", element: <Navigate to="/admin/app" /> }];

  const routes = !auth.user ? publicRoutes : protectedRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
