import { zodResolver } from "@hookform/resolvers/zod";
import { twMerge } from "tailwind-merge";
import * as React from "react";
import { useForm } from "react-hook-form";

export const Form = ({
  onSubmit,
  children,
  className,
  options,
  id,
  schema,
  ...props
}) => {
  const methods = useForm({
    ...options,
    resolver: schema && zodResolver(schema),
  });
  return (
    <form
      className={twMerge("space-y-6", className)}
      onSubmit={methods.handleSubmit(onSubmit)}
      id={id}
      {...props}
    >
      {children(methods)}
    </form>
  );
};
