export const nodes = [
    {
        id: '1',
        type: 'imageNode',
        // parentNode: 'g1',
        data: {
            label: 'Get the list of calls scheduled. Fetch customer name and call type',
            image: {
                url: "/execution/googleCalender.svg",
                alt: "calender"
            }
        },
        position: { x: 0, y: 0 },
        // extent: 'parent',
    },
    {
        id: '2',
        type: 'imageNode',
        data: {
            shape: 'round-rect',
            label: 'Loop through each call',
            hasNodeDetails: true
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '3',
        type: 'shapeNode',
        data: {
            label: 'if call is QBR',
            shape: 'diamond',
            height: 100,
            width: 150
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '4',
        type: 'shapeNode',
        data: {
            label: 'if call is onboarding',
            shape: 'diamond',
            height: 100,
            width: 150
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '5',
        type: 'imageNode',
        data: {
            hasNodeDetails: true,
            label: 'Get Opportunity name, value, closed date and the opportunity owner name of the customer.',
            image: {
                url: "/execution/salesForce.svg",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '6',
        type: 'imageNode',
        data: {
            hasNodeDetails: true,
            label: 'Get the list of call participants',
            image: {
                url: "/execution/googleCalender.svg",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '7',
        type: 'imageNode',
        data: {
            hasNodeDetails: true,
            label: 'Get the participants full name and designation',
            image: {
                url: "/execution/salesForce.svg",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '8',
        type: 'imageNode',
        data: {
            label: 'Get the total number of cases created by the customer in last 90 days',
            image: {
                url: "/execution/salesForce.svg",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '9',
        type: 'imageNode',
        data: {
            label: 'Get the total number of email and phone numbers captured, leads created and meetings booked in the last 90 days',
            image: {
                url: "/execution/3.png",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '10',
        type: 'imageNode',
        data: {
            label: 'Get the total number of email and phone numbers captured, leads created and meetings booked in the last 90 days before that',
            image: {
                url: "/execution/3.png",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '11',
        type: 'imageNode',
        data: {
            label: 'Compare both product usage results and get the product usage health. Health is high if any of the three metrics is performing high in last 90 days compared to the previous 90 days',
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '12',
        type: 'imageNode',
        data: {
            label: 'Get the number of playbooks created and number of active playbooks.',
            image: {
                url: "/execution/3.png",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '13',
        type: 'imageNode',
        data: {
            label: 'Get the top playbook which has captured most number of emails and leads created in last 90 days',
            image: {
                url: "/execution/3.png",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '14',
        type: 'imageNode',
        data: {
            label: `Get the customer health as Risk, Concerning and Healthy

Risk means the customer has at least one case in the open stage for more than 30 days and the product usage health is low, and the overall product usage health is low

Concerning means the customer has at least one case in the open stage for more than 30 days or the product usage health is low, or overall product usage health is low

Healthy means the customer does not have any case in the open stage for more than 30 days and both the product usage health is high`,
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '15',
        type: 'shapeNode',
        data: {
            label: 'if call is support',
            shape: 'diamond',
            height: 100,
            width: 150
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '16',
        type: 'imageNode',
        data: {
            label: 'Get Opportunity name, value, closed date and the opportunity owner name of the customer.',
            image: {
                url: "/execution/salesForce.svg",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '17',
        type: 'imageNode',
        data: {
            label: 'Get the list of call participants',
            image: {
                url: "/execution/googleCalender.svg",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '18',
        type: 'imageNode',
        data: {
            label: 'Get the participants full name and designation',
            image: {
                url: "/execution/salesForce.svg",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '19',
        type: 'imageNode',
        data: {
            label: 'Get Opportunity name, value, closed date and the opportunity owner name of the customer.',
            image: {
                url: "/execution/salesForce.svg",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '20',
        type: 'imageNode',
        data: {
            label: 'Get the list of call participants',
            image: {
                url: "/execution/googleCalender.svg",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '21',
        type: 'imageNode',
        data: {
            label: 'Get the participants full name and designation',
            image: {
                url: "/execution/salesForce.svg",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '22',
        type: 'imageNode',
        data: {
            label: 'Get a list of cases that are not closed',
            image: {
                url: "/execution/salesForce.svg",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '23',
        type: 'imageNode',
        data: {
            label: 'Loop through each case',
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '24',
        type: 'imageNode',
        data: {
            label: 'Get the relevant article ro resolve the case',
            image: {
                url: "/execution/salesForce.svg",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '25',
        type: 'imageNode',
        data: {
            label: 'Get the total number of cases created by the customer in last 1 year',
            image: {
                url: "/execution/salesForce.svg",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '26',
        type: 'imageNode',
        data: {
            label: 'Get the total number of email and phone numbers captured, leads created and meetings booked in the last 90 days',
            image: {
                url: "/execution/3.png",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '27',
        type: 'imageNode',
        data: {
            label: 'Get the total number of email and phone numbers captured, leads created and meetings booked in the last 90 days before that',
            image: {
                url: "/execution/3.png",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '28',
        type: 'imageNode',
        data: {
            label: 'Compare both product usage results and get the product usage health. Health is high if any of the three metrics is performing high in last 90 days compared to the previous 90 days',
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '29',
        type: 'imageNode',
        data: {
            label: 'Get the number of playbooks created and number of active playbooks.',
            image: {
                url: "/execution/3.png",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '30',
        type: 'imageNode',
        data: {
            label: 'Get the top playbook which has captured most number of emails and leads created in last 90 days',
            image: {
                url: "/execution/3.png",
                alt: "my first image"
            }
        },
        position: { x: 0, y: 0 },
    },
    {
        id: '31',
        type: 'imageNode',
        data: {
            label: `Get the customer health as Risk, Concerning and Healthy

            Risk means the customer has at least one case in the open stage for more than 30 days and the product usage health is low, and the overall product usage health is low
            
            Concerning means the customer has at least one case in the open stage for more than 30 days or the product usage health is low, or overall product usage health is low
            
            Healthy means the customer does not have any case in the open stage for more than 30 days and both the product usage health is high`,
        },
        position: { x: 0, y: 0 },
    },
];

export const edges = [
    {
        id: '1->2',
        source: '1',
        target: '2',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '2->3',
        source: '2',
        target: '3',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '3->4',
        source: '3',
        target: '4',
        label: "Yes",
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '3->5',
        source: '3',
        target: '5',
        label: "No",
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '5->6',
        source: '5',
        target: '6',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '6->7',
        source: '6',
        target: '7',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '7->8',
        source: '7',
        target: '8',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '8->9',
        source: '8',
        target: '9',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '9->10',
        source: '9',
        target: '10',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '10->11',
        source: '10',
        target: '11',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '11->12',
        source: '11',
        target: '12',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '12->13',
        source: '12',
        target: '13',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '13->14',
        source: '13',
        target: '14',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '4->15',
        source: '4',
        target: '15',
        sourceHandle: 'bottom',
        targetHandle: 'top',
        label: "No"
    },
    {
        id: '4->16',
        source: '4',
        target: '16',
        sourceHandle: 'bottom',
        targetHandle: 'top',
        label: "Yes"
    },
    {
        id: '16->17',
        source: '16',
        target: '17',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '17->18',
        source: '17',
        target: '18',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '15->19',
        source: '15',
        target: '19',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '19->20',
        source: '19',
        target: '20',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '20->21',
        source: '20',
        target: '21',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '21->22',
        source: '21',
        target: '22',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '22->23',
        source: '22',
        target: '23',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '23->24',
        source: '23',
        target: '24',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '24->25',
        source: '24',
        target: '25',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '25->26',
        source: '25',
        target: '26',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '26->27',
        source: '26',
        target: '27',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '27->28',
        source: '27',
        target: '28',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '28->29',
        source: '28',
        target: '29',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '29->30',
        source: '29',
        target: '30',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
    {
        id: '30->31',
        source: '30',
        target: '31',
        sourceHandle: 'bottom',
        targetHandle: 'top',
    },
];
