import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
    MarkerType,
    useReactFlow,
    applyNodeChanges,
    applyEdgeChanges,
    ConnectionMode,
    addEdge,
    Controls,
} from "reactflow";

import ImageNode from "./ImageNode";
import ShapeNode from "./ShapeNode";
import useAutoLayout from "../hooks/useAutoLayout";
import * as initialElements from "../InitialElements";
import "reactflow/dist/style.css";
import { twMerge } from "tailwind-merge";
import ClassNode from "./ClassNode";

const nodeTypes = {
    imageNode: ImageNode,
    shapeNode: ShapeNode,
    classNode: ClassNode,
};

const proOptions = {
    account: "paid-pro",
    hideAttribution: true,
};

const defaultEdgeOptions = {
    type: "smoothstep",
    markerEnd: { type: MarkerType.ArrowClosed },
    pathOptions: { offset: 5 },
};

/**
 * This example shows how you can automatically arrange your nodes after adding child nodes to your graph.
 */
export function ReactFlowComponent({ initialNodes, initialEdges, direction = "TB", className, style, height, width, ...props }) {
    // this hook handles the computation of the layout once the elements or the direction changes
    const wrapperRef = useRef(null);
    const { fitView } = useReactFlow();

    useAutoLayout({ direction, height: height, width: width });
    // useAutoLayout({ direction, height: 400, width: 340 }); 
    // const [nodes, setNodes] = useState(initialElements.nodes);
    // const [edges, setEdges] = useState(initialElements.edges);
    const [nodes, setNodes] = useState(initialNodes || initialElements.nodes);
    const [edges, setEdges] = useState(initialEdges || initialElements.edges);
    const onConnect = useCallback(
        (edge) => setEdges((eds) => addEdge(edge, eds)),
        [setEdges]
    );

    const onNodesChange = (changes) => {
        setNodes((nodes) => applyNodeChanges(changes, nodes));
    };

    const onEdgesChange = (changes) => {
        setEdges((edges) => applyEdgeChanges(changes, edges));
    };

    // every time our nodes change, we want to center the graph again
    useEffect(() => {
        if (props.fit)
            fitView({ duration: 10 });
    }, [nodes, fitView]);

    return (
        <div
            ref={wrapperRef}
            className={twMerge(`w-full h-screen`, className)}
            style={style}
        // style={{ width: "100%", height: "100vh" }}
        >
            <ReactFlow
                panOnScroll={true}
                proOptions={proOptions}
                nodeTypes={nodeTypes}
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                fitView
                fitViewOptions={{ maxZoom: 0.8 }}
                onlyRenderVisibleElements={true}
                // newly added edges get these options automatically
                defaultEdgeOptions={defaultEdgeOptions}
                connectionMode={ConnectionMode.Loose}
            >
                <Controls position="bottom-right" />
            </ReactFlow>
        </div>
    );
}