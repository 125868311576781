import { Link } from "react-router-dom";
import * as z from "zod";

import { Button } from "@/components/Elements";
import { Form, InputField } from "@/components/Form";
import { useAuth } from "@/lib/auth";

const schema = z.object({
  email: z.string().min(1, "Required"),
  password: z.string().min(1, "Required"),
});

export const LoginForm = ({ onSuccess }) => {
  const { login, isLoggingIn } = useAuth();

  return (
    <div>
      <Form
        className="space-y-4"
        onSubmit={async (values) => {
          if (isLoggingIn) return;
          await login({ "username": values.email, "password": values.password });
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email"
              labelTextClassName="flex flex-col gap-2"
              className="rounded-xl"
              placeholder="email@company.com"
              error={formState.errors["email"]}
              registration={register("email")}
            />
            <div className="flex flex-col gap-2">
              <InputField
                type="password"
                label="Password"
                labelTextClassName="flex flex-col gap-2"
                className="rounded-xl"
                placeholder="●●●●●●●●●●●●●●"
                error={formState.errors["password"]}
                registration={register("password")}
              />
              {/* <div className="flex items-center justify-end">
                <div className="text-xs">
                  <Link
                    to="../forget-password"
                    className="font-medium font-inter-500 text-brand-ai-100 hover:text-brand-ai-100"
                  >
                    Forget password?
                  </Link>
                </div>
              </div> */}
            </div>
            <div className="mt-8">
              <Button
                // isLoading={isLoggingIn}
                type="submit"
                className="w-full rounded-xl bg-brand-ai-100 btn-style font-inter-500 text-sm font-medium"
              >
                Login
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
