import { useNavigate } from "react-router-dom";

import { Layout } from "../components/Layout";
import { LoginForm } from "../components/LoginForm";

export const Login = () => {
  const navigate = useNavigate();

  return (
    <Layout
      title="Welcome"
      describtion="Login to your account"
      describtionClassName="text-center"
    >
      <LoginForm onSuccess={() => navigate("/admin/chat")} />
    </Layout>
  );
};
