import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

export const CheckBox = ({
  label,
  onChange,
  checked,
  labelClassName,
  className,
}) => {
  const [isChecked, setIsChecked] = useState(checked || false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    onChange && onChange(!isChecked);
  };

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleToggle}
        className={twMerge(
          "h-5 w-5 text-brand-ai-500 rounded focus:ring-brand-ai-500 border-gray-300",
          className
        )}
      />
      <label className={twMerge("ml-2", labelClassName)}>{label}</label>
    </div>
  );
};
