export const ViewFlowDetailData = [
  {
    id: "1",
    accountID: "123",
    accountName: "Walmart",
    accountOwnerFirstName: "Prasanna",
    accountOwnerLastName: "V",
    accountOwnerEmail: "pv@petavue.com",
    opportunityValue: "$ 13,000",
    opportunityOwnerFirstName: "Prasanna",
    opportunityOwnerLastName: "V",
    opportunityOwnerEmail: "pv@petavue.com",
    opportunityOwnerCloseData: "2022"
  },
  {
    id: "2",
    accountID: "126",
    accountName: "Amazon",
    accountOwnerFirstName: "Ijas",
    accountOwnerLastName: "Ahamed",
    accountOwnerEmail: "ijas@petavue.com",
    opportunityValue: "$ 16,000",
    opportunityOwnerFirstName: "Ijas",
    opportunityOwnerLastName: "Ahamed",
    opportunityOwnerEmail: "ijas@petavue.com",
    opportunityOwnerCloseData: "2022"
  },
  {
    id: "3",
    accountID: "434",
    accountName: "Apple",
    accountOwnerFirstName: "Prasanna",
    accountOwnerLastName: "V",
    accountOwnerEmail: "pv@petavue.com",
    opportunityValue: "$ 21,000",
    opportunityOwnerFirstName: "Prasanna",
    opportunityOwnerLastName: "V",
    opportunityOwnerEmail: "pv@petavue.com",
    opportunityOwnerCloseData: "2022"
  },
  {
    id: "4",
    accountID: "323",
    accountName: "Tesla",
    accountOwnerFirstName: "Prasanna",
    accountOwnerLastName: "V",
    accountOwnerEmail: "pv@petavue.com",
    opportunityValue: "$ 9,000",
    opportunityOwnerFirstName: "Prasanna",
    opportunityOwnerLastName: "V",
    opportunityOwnerEmail: "pv@petavue.com",
    opportunityOwnerCloseData: "2022"
  },
  {
    id: "5",
    accountID: "535",
    accountName: "Microsoft",
    accountOwnerFirstName: "Ijas",
    accountOwnerLastName: "Ahamed",
    accountOwnerEmail: "ijas@petavue.com",
    opportunityValue: "$ 14,500",
    opportunityOwnerFirstName: "Ijas",
    opportunityOwnerLastName: "Ahamed",
    opportunityOwnerEmail: "ijas@petavue.com",
    opportunityOwnerCloseData: "2022"
  },
  {
    id: "6",
    accountID: "134",
    accountName: "HP",
    accountOwnerFirstName: "Prasanna",
    accountOwnerLastName: "V",
    accountOwnerEmail: "pv@petavue.com",
    opportunityValue: "$ 14,000",
    opportunityOwnerFirstName: "Prasanna",
    opportunityOwnerLastName: "V",
    opportunityOwnerEmail: "pv@petavue.com",
    opportunityOwnerCloseData: "2022"
  },
  {
    id: "7",
    accountID: "125",
    accountName: "Intuit",
    accountOwnerFirstName: "Ijas",
    accountOwnerLastName: "Ahamed",
    accountOwnerEmail: "ijas@petavue.com",
    opportunityValue: "$ 19,400",
    opportunityOwnerFirstName: "Ijas",
    opportunityOwnerLastName: "Ahamed",
    opportunityOwnerEmail: "ijas@petavue.com",
    opportunityOwnerCloseData: "2022"
  },
  {
    id: "8",
    accountID: "635",
    accountName: "Alphabet",
    accountOwnerFirstName: "Ijas",
    accountOwnerLastName: "Ahamed",
    accountOwnerEmail: "ijas@petavue.com",
    opportunityValue: "$ 10,000",
    opportunityOwnerFirstName: "Ijas",
    opportunityOwnerLastName: "Ahamed",
    opportunityOwnerEmail: "ijas@petavue.com",
    opportunityOwnerCloseData: "2022"
  },
];
