import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import PropTypes from "prop-types";

export const Toggle = ({ checked, onChange }) => {
  const [isEnabled, setIsEnabled] = useState(checked);

  const handleToggle = () => {
    setIsEnabled(!isEnabled);
    onChange(!isEnabled);
  };

  return (
    <Switch
      checked={isEnabled}
      onChange={handleToggle}
      className={`${
        isEnabled ? "bg-brand-ai-400" : "bg-gray-200"
      } relative inline-flex items-center h-3 rounded-full w-8`}
    >
      <span className="sr-only">Toggle</span>
      <span
        className={`${
          isEnabled
            ? "translate-x-3 duration-300"
            : "translate-x-0 duration-300"
        } inline-block w-5 h-5 transform bg-brand-ai-500 rounded-full`}
      />
    </Switch>
  );
};

Toggle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};
