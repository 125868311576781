import React, { createContext, useContext, useState, useCallback } from "react";

const NewInsightContext = createContext();

export function NewInsightProvider({ children }) {
  const [thoughtProcess, setThoughtProcess] = useState([]);
  const [progressStatus, setProgressStatus] = useState(1);
  const [size, setSize] = useState({ y: 200 });
  //1.generate
  //2.Regenerate
  //3.stop Generating
  //4.generated
  //5.loading

  const [openThoughtProcess, setOpenThoughtProcess] = useState(false);
  const [isNewThoughtProcess, setIsNewThoughtProcess] = useState(false);


  const addThoughtProcess = (value) => {
    setThoughtProcess((pre) => [...pre, value]);
  };

  const clearThoughtProcess = useCallback(() => {
    setThoughtProcess([]);
  }, []);

  return (
    <NewInsightContext.Provider
      value={{
        thoughtProcess,
        setThoughtProcess,
        addThoughtProcess,
        progressStatus,
        setProgressStatus,
        openThoughtProcess,
        setOpenThoughtProcess,
        isNewThoughtProcess,
        setIsNewThoughtProcess,
        clearThoughtProcess,
        size,
        setSize,
      }}
    >
      {children}
    </NewInsightContext.Provider>
  );
}

export function useNewInsightContext() {
  return useContext(NewInsightContext);
}
