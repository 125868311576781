import * as React from "react";
import PropTypes from "prop-types";
import { FieldWrapper } from "../../Form/FieldWrapper";
import { twMerge } from "tailwind-merge";

const sizes = {
  xs: "w-32",
  sm: "w-40",
  md: "w-60",
  lg: "w-80",
  xl: "w-96",
  full: "w-full",
};

export const Input = React.forwardRef(
  (
    {
      type = "text",
      label,
      className,
      wrapperClassName,
      registration,
      error,
      placeholder = "Placeholder",
      defaultValue,
      name,
      size = "md",
      onChange = () => {},
      value,
      disabled = false,
      icon,
      onKeyDown,
      iconClassName,
      labelClassName,
      button,
      labelTextClassName,
      ...remainingProps
    },
    ref
  ) => {
    return (
      <FieldWrapper
        label={label}
        error={error}
        wrapperClassName={wrapperClassName}
        className={labelClassName}
        labelTextClassName={labelTextClassName}
      >
        <div
          ref={ref}
          className={`w-full ${twMerge(icon && "block relative", sizes[size])}`}
        >
          <input
            onChange={(event) => onChange(event)}
            onKeyDown={onKeyDown}
            disabled={disabled}
            name={name}
            type={type}
            className={twMerge(
              "relative appearance-none w-full block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-brand-primary-500 sm:text-sm",
              className
            )}
            {...registration}
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder}
            {...remainingProps}
          />
          {icon && (
            <span
              className={`absolute inset-y-0 ${iconClassName} flex items-center pr-3`}
            >
              {icon}
            </span>
          )}
        </div>
      </FieldWrapper>
    );
  }
);

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "full"]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  onChange: PropTypes.func,
};
