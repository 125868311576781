import clsx from "clsx";

const sizes = {
  sm: "h-4 w-4",
  md: "h-8 w-8",
  lg: "h-16 w-16",
  xl: "h-32 w-32",
};

const variants = {
  light: "text-white",
  primary: "text-blue-600",
};

export const Spinner = ({
  size = "md",
  variant = "primary",
  className = "",
}) => {
  return (
    <div
      className={clsx("loader", sizes[size], variants[variant], className)}
    />
  );
};
// Cherry picks