import { Transition } from "@headlessui/react";
import { X } from "phosphor-react";
import { twMerge } from "tailwind-merge";

export const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  header,
  className,
  headerClassName,
  titleClassName,
  closeClassName,
  childClassName,
  transition = true,
  showCloseBtn = true,
  opacity = 50
}) => {
  return (
    <Transition
      show={isOpen}
      enter={transition ? "transition-opacity duration-300" : ""}
      enterFrom={transition ? "opacity-0" : ""}
      enterTo={transition ? "opacity-100" : ""}
      leave={transition ? "transition-opacity duration-300" : ""}
      leaveFrom={transition ? "opacity-100" : ""}
      leaveTo={transition ? "opacity-0" : ""}
    >
      <div className="fixed inset-0 flex items-center justify-center z-10">
        <div
          className={`fixed inset-0 bg-black bg-opacity-${opacity}`}
          onClick={onClose}
        />
        <div
          className={twMerge(
            "bg-white w-96 mx-auto rounded-lg shadow-xl z-50",
            className
          )}
        >
          <div
            className={twMerge(
              "flex items-center relative justify-between px-8 pt-6 pb-4",
              headerClassName
            )}
          >
            {header && <div className="w-full">{header}</div>}
            <h2
              className={twMerge(
                "text-lg font-semibold font-inter-600",
                titleClassName
              )}
            >
              {title}
            </h2>
            {showCloseBtn && <button
              className={twMerge("text-gray-600 absolute right-7 top-7 hover:text-gray-800 transition-colors", closeClassName)}
              onClick={onClose}
            >
              <X size={20} weight="bold" />
            </button>}
          </div>
          <div className={childClassName}>{children}</div>
        </div>
      </div>
    </Transition>
  );
};
